import React from 'react';
import {
  QuoteModel,
  OrderModel,
  CategoryModel,
  OrganizationModel,
} from '@nimles/models';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Column,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { Link } from 'gatsby-plugin-react-i18next';
import moment from 'moment';

import { ProductGroupModel } from '@nimles/models';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Price } from '../../price/Price';
import { Flex } from '@nimles/react-web-components';
import styled from '@emotion/styled';

const Label = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  color: #555;
`;
const Value = styled.div``;

export const Orders = (props: any) => {
  const { t } = useTranslation();
  const orders = useSelector<RootState, OrderModel[]>(
    ({ orders }) => orders.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const publicOrganizations = useSelector<RootState, OrganizationModel[]>(
    ({ publicOrganizations }) => publicOrganizations.values
  );

  return (
    <Row>
      <Column xs={100}>
        <h2>{t('title.myOrders')}</h2>
        {orders
          .sort(({ lastModified: a }, { lastModified: b }) =>
            moment(a).isBefore(moment(b)) ? 1 : -1
          )
          .map(
            ({
              id,
              head: {
                orderNumber,
                orderDate,
                orderStatus,
                totalNetPrice,
                currency,
                buyer: {
                  deliveryAddress: { city },
                },
              },
              lines,
              lastModified,
            }) => {
              const groups = lines.map(
                ({ id, categoryId, productGroupId }) =>
                  `${
                    publicCategories.find(({ id }) => id === categoryId)
                      ?.name ?? ''
                  } ${
                    publicProductGroups.find(({ id }) => id === productGroupId)
                      ?.name ?? ''
                  }`
              );

              return (
                <Link to={`/account/orders/${id}`}>
                  <Card>
                    <CardBody>
                      <Flex container justify="space-between">
                        <Flex item>
                          <Label>{t('prop.date')}</Label>
                          <Value>{moment(orderDate).format('MMMM D')}</Value>
                        </Flex>
                        <Flex item>
                          <Label>{t('prop.order')}</Label>
                          <Value>{orderNumber}</Value>
                        </Flex>
                        <Flex item>
                          <Label>{t('prop.status')}</Label>
                          <Value>{orderStatus}</Value>
                        </Flex>
                        <Flex item container alignItems="center">
                          <Label>{t('prop.quantity')}</Label>
                          <Value>
                            {lines.reduce(
                              (sum, { quantity }) => sum + quantity,
                              0
                            )}
                          </Value>
                        </Flex>
                        <Flex item container alignItems="flex-end">
                          <Label>{t('prop.amount')}</Label>
                          <Value>
                            <Price value={totalNetPrice} currency={currency} />
                          </Value>
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                </Link>
              );
            }
          )}
      </Column>
    </Row>
  );
};

import React from 'react';
import {
  loadFiles,
  loadPublicFile,
  loadPublicOrganization,
  loadPublicProductProperty,
  loadOrder,
} from '@nimles/react-redux';
import {
  OrderModel,
  ProductGroupModel,
  ProductPropertyModel,
  CategoryModel,
  FileModel,
  QuoteModel,
  OrganizationModel,
} from '@nimles/models';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Column,
  Flex,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import moment from 'moment';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Property, PropertyTitle, PropertyValue } from '../../Property';
import { Price } from '../../price/Price';
import { getImageUrl } from '../../../utils';
import styled from '@emotion/styled';

const Label = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  color: #555;
`;
const Value = styled.div``;

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const Order = ({ orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orders = useSelector<RootState, OrderModel[]>(
    ({ orders }) => orders.values
  );
  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );
  const publicCategories = useSelector<RootState, CategoryModel[]>(
    ({ publicCategories }) => publicCategories.values
  );
  const publicOrganizations = useSelector<RootState, OrganizationModel[]>(
    ({ publicOrganizations }) => publicOrganizations.values
  );
  const publicProductGroups = useSelector<RootState, ProductGroupModel[]>(
    ({ publicProductGroups }) => publicProductGroups.values
  );
  const files = useSelector<RootState, FileModel[]>(
    ({ files }) => files.values
  );
  const publicFiles = useSelector<RootState, FileModel[]>(
    ({ publicFiles }) => publicFiles.values
  );
  const publicProductProperties = useSelector<
    RootState,
    ProductPropertyModel[]
  >(({ publicProductProperties }) => publicProductProperties.values);
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  useEffect(() => {
    dispatch(loadFiles());
  }, [accessToken]);

  useEffect(() => {
    if (orderId) {
      dispatch(loadOrder(orderId));
    }
  }, [accessToken, orderId]);

  const order = useMemo(() => orders.find(({ id }) => id === orderId), [
    orders,
    orderId,
  ]);

  useEffect(() => {
    order?.lines.forEach((line) => {
      if (!line.fileIds) {
        return;
      }
      line.productProperties
        .filter(
          ({ productPropertyId }) =>
            !publicProductProperties.some(({ id }) => id === productPropertyId)
        )
        .forEach(({ productPropertyId }) =>
          dispatch(loadPublicProductProperty(productPropertyId))
        );
    });
  }, [order?.lines]);

  if (!order) {
    return null;
  }

  const {
    head: {
      orderNumber,
      orderDate,
      orderStatus,
      buyer: {
        deliveryAddress: { city },
      },
    },
    lines,
  } = order;

  return (
    <Row wrap="wrap">
      <Column xs={100} xxl={60}>
        <h2>{t('prop.order')}</h2>
        <Card>
          <CardHeader>
            <Flex container justify="space-between">
              <Flex item>
                <Label>{t('prop.date')}</Label>
                <Value>{moment(orderDate).format('MMMM D')}</Value>
              </Flex>
              <Flex item>
                <Label>{t('prop.order')}</Label>
                <Value>{orderNumber}</Value>
              </Flex>
              <Flex item>
                <Label>{t('prop.status')}</Label>
                <Value>{orderStatus}</Value>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <List>
              {lines.map((line) => {
                return (
                  <ListItem>
                    <div>
                      <Row align="center" margin="0 -15px 10px">
                        <Column>
                          {line.imageIds?.length ? (
                            <Image src={getImageUrl(line.imageIds[0])} />
                          ) : null}
                        </Column>
                        <Column flex>
                          <Row justify="flex-start">
                            <Column>
                              <strong>{line?.name}</strong>
                            </Column>
                          </Row>
                          <Row>
                            <Column>{line.quantity}x</Column>
                            <Column flex>
                              <Price
                                value={line.unitNetPrice}
                                currency={order.head.currency}
                              />
                            </Column>
                            <Column>
                              <Price
                                value={line.unitNetPrice * line.quantity}
                                currency={order.head.currency}
                              />
                            </Column>
                          </Row>
                        </Column>
                      </Row>
                    </div>
                  </ListItem>
                );
              })}
              <ListItem>
                <Row>
                  <Column flex>Excl Vat</Column>
                  <Column>
                    <Price
                      value={order.head.totalGrossPrice}
                      currency={order.head.currency}
                    />
                  </Column>
                </Row>
              </ListItem>
              <ListItem>
                <Row>
                  <Column flex>Vat</Column>
                  <Column>
                    <Price
                      value={order.head.totalVat}
                      currency={order.head.currency}
                    />
                  </Column>
                </Row>
              </ListItem>
              <ListItem>
                <Row>
                  <Column flex>Total incl Vat</Column>
                  <Column>
                    <Price
                      value={order.head.totalNetPrice}
                      currency={order.head.currency}
                    />
                  </Column>
                </Row>
              </ListItem>
            </List>
          </CardBody>
        </Card>
      </Column>
      <Column xs={100} xxl={40}></Column>
    </Row>
  );
};
